/* Screens */

export const SCREEN = {
    DASHBOARD: 'dashboard',
    ASK_QUESTION: 'ask_a_question',
    FAQS: 'faqs',
    THE_END: 'theEnd',
    REQUEST_CONSULT: 'schedule_consultation',
    WIDGET: 'widget',
    SEE_ALL_SERVICES: 'see_all_services',
    INSTANT_QUOTE: 'instant_quote',
    PROCEDURE_ELIGIBILITY: 'procedure_eligibility',
    PROVIDERS: 'providers',
    SERVICES_IFRAME: 'service_iframe',
    TOP_QUESTION: 'top_question',
    LOCATIONS: 'locations',
    NON_SURGICAL_SERVICES: 'non_surgical_services',
    CONSULT_SUMMARY: 'consult_summary',
    CONSULT_SUMMARY_QUESTION: 'consult_summary_question',
    TEXT_US: 'text_us',
    CONSULT_SCHEDULING: 'consult_scheduling',
    PROVIDER_SCHEDULING: 'provider_scheduling',
    SERVICE_SUMMARY: 'service_summary',
    INTERESTS: 'interests',
    FULL_NAME: 'fullNames',
    EMAIL: 'email',
    PHONE: 'phone',
    INSTANT_QUOTE_SUMMARY: 'instant_quote_summary',
    BOOK_APPOINTMENT: 'book_appointment',
    SURGICAL: 'surgical',
    VALIDATE_DIGITS: 'validate_digits',
    SELECT_TIME: 'select_time',
    SELECT_ONLY_TIME: 'select_only_time',
    SELECT_ONLY_DATE: 'select_only_date',
    MAIN_MENU: 'mainMenu__container',
    REGISTER_NEW_PATIENT: 'register-new-patient',
    APPOINTMENT_SUMMARY: 'appointment-summary',
    APPOINTMENT_CONFIRMED: 'appointment-confirmed',
    UPLOAD_PHOTOS: 'upload_photos',
    TEXTAREA_QUESTION: 'textarea_question',
    TEXT_QUESTION: 'text_question',
    SELECT_QUESTION: 'select_question',
    CHECKBOX_QUESTION: 'checkbox_question',
    DATE_QUESTION: 'date_question',
    NUMBER_QUESTION: 'number_question',
    SELECT_GENDER: 'select_gender',
    HEIGHT_QUESTION: 'height_question',
    BODY_AREA_SELECTION: 'body_area_selection',
    VERIFY_INFORMATION: 'verifyInformation',
    PATIENTS: 'patients',
    NOTFOUND_APPOINTMENT: 'notfound_appointment',
    CALLUS: 'callus',
};

export const MOBILE_SCREEN = window.innerWidth < 576;

export const DESKTOP_SCREEN = window.innerWidth >= 1025;

/* Addons select type */
export const ADDONS_SELECT_TYPE = {
    MULTI_SELECT: 'multi_select',
    SINGLE_SELECT: 'single_select',
};

/* Countries */
export const countriesNotSupported = [
    'AC',
    'BQ',
    'GP',
    'MF',
    'NC',
    'PM',
    'RE',
    'SH',
    'SJ',
    'TA',
    'WF',
    'YT',
];

export const countriesPrioritized = ['US', 'GB', 'BR', 'FR', 'MX'];

/* Backgrounds */
export const BACKGROUND = {
    LIGHT: 'light',
    DARK: 'dark',
};

export const OPEN = 'open';
export const CLOSE = 'close';

export const HIDE = 'hide';

export const SERVICES_BUTTON = {
    GET_PRICING: 'Get Pricing',
    REQUEST_CONSULTATION: 'Request Consultation',
    BOOK_APPOINTMENT: 'Book Appointment',
};

export const EVENT_MESSAGE = {
    REFRESH: 'refresh',
    DASHBOARD: 'dashboard',
    ASK_QUESTION: 'ask_a_question',
    THE_END: 'theEnd',
    REQUEST_CONSULT: 'schedule_consultation',
    WIDGET: 'widget',
    SEE_ALL_SERVICES: 'see_all_services',
    INSTANT_QUOTE: 'instant_quote',
    PROCEDURE_ELIGIBILITY: 'procedure_eligibility',
    PROVIDERS: 'providers',
    SERVICES_IFRAME: 'service_iframe',
    TOP_QUESTION: 'top_question',
    LOCATIONS: 'locations',
    NON_SURGICAL_SERVICES: 'non_surgical_services',
    CONSULT_SUMMARY: 'consult_summary',
    TEXT_US: 'text_us',
    FINISH_LOADING: 'finishLoading',
    FINISH_LOADING_IFRAME: 'finishLoadIframe',
    SELECT_GENDER: 'select_gender',
    BOOK_APPOINTMENT: 'book_appointment',
    BOOK_APPOINTMENT_MODAL: 'book_appointment_modal',
    TOGGLE_WIDGET: 'toggle_widget',
    OPEN_BOOK_APPOINTMENT_FLOW: 'open_book_appointment_flow',
};

export const PROCEDURE_SELECTION_TYPE = {
    MULTI: 'multi',
    SINGLE: 'single',
};

export const PATIENT_TYPE = {
    NEW_PATIENT: 'new-user',
    EXISTING: 'existing-user',
};

export const NULL_IMAGE_URL_STRING =
    'https://storage.googleapis.com/instamate-images-dev/';

export const APPOINTMENT_DATE_RANGE = 150;

export const ESTIMATE_ID_PARAM = 'estimate_id';
export const EVENT_ID_PARAM = 'event_id';
export const UPLOAD_ID_PARAM = 'upload_id';
export const NEW_LEAD_UPLOAD = 'upload';
export const IGNORE_IFRAME = 'ignore_iframe';
export const CATEGORY_PROJECT = 'plastic_surgery';
export const IS_LOAD_INSTANCE_QUOTE = 'IS_LOAD_INSTANCE_QUOTE';
export const HIDE_WIDGET = 'hide_widget';
export const BOOK_APPOINTMENT_PARAM = 'book_appointment';
export const OPEN_BOOK_APPOINTMENT_MODAL = 'open__book_appointment_modal';
export const FULL_PAGE = 'full_page';
export const ESTIMATE = 'estimate';
export const APPOINTMENT_CHANNEL_ID = 'appointment_channel_id';
export const UPDATE_APPOINTMENT_CHANNEL = 'updating';

// APPOINTMENT REQUEST TYPE
export const APPOINTMENT_REQUEST_FORM = 'form';
export const APPOINTMENT_REQUEST_CALENDAR = 'calendar';